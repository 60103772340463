<template>
    <div style="background-color: white;width: calc(100vw - 215px);height: calc(100vh - 50px);overflow-y: auto;">
        <div style="padding: 20px;box-sizing: border-box;margin: 0 auto;width: 80%;margin-top: 20px;max-width: 1340px;">
            <div style="display: flex;align-items: center;justify-content: flex-start;margin-top: 10px;">
                <img src="../../assets/image/arrow_left.png" style="width: 24px;height: 24px;cursor: pointer;"
                    @click="goBack">
                <p style="color: #333;font-size: 18px;font-weight: bold;">Subscription Payment</p>
            </div>
            <div style="width: 100%;">
                <div class="pay-info">
                    <div style="display: flex;justify-content: space-between;">
                        <p>Item</p>
                        <p style="font-weight: bold;">EAE Universal Pass</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Period</p>
                        <p style="font-weight: bold;">{{ year > 1 ? year + ' Years' : year + ' Year' }}</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Regular Subscription Fee</p>
                        <p style="font-weight: bold;">980 SGD per Year</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Discount</p>
                        <p style="font-weight: bold;">{{ discount }} SGD</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;">
                        <p>Payment Service Fee</p>
                        <p style="font-weight: bold;">{{ serviceFee }} SGD</p>
                    </div>

                    <div style="display: flex;justify-content: space-between;margin-top: 40px;">
                        <p style="font-weight: bold;">Total Order Amount</p>
                        <p style="font-weight: bold;color: var(--c-primary) ;">{{ amount }} SGD</p>
                    </div>

                </div>
                <p style="color: #666;margin-top: 10px;margin-left: 30px;">*The service fee is charged by the payment
                    platform. It is not included in the registration fee.</p>

                <p style="color: #333;font-size: 18px;font-weight: bold;margin-top: 40px;">Terms and Conditions</p>
                <div class="terms">
                    To request a refund, students must submit a formal written request titled "EAE Universal Pass Refund
                    Request | Student Name" to contact@eae.international. The refund policy is as follows:
                    <br>
                    <br>
                    1. If the student has utilized any benefits of the EAE Universal Pass to purchase or register for
                    paid projects, products, or services, the cost of these will be calculated based on their individual
                    prices and recorded as project costs.
                    <br>
                    <br>
                    2. The monthly cost of the EAE Universal Pass will be calculated based on the student's subscription
                    fee, the corresponding subscription term, and the month in which the refund request is made. The
                    monthly cost is determined by dividing the total subscription fee by the number of months in the
                    subscription term.
                    <br>
                    <br>
                    3. The incurred monthly costs will be calculated by multiplying the monthly cost by the number of
                    months or partial months the pass was active. For the purpose of this calculation, any part of a
                    month will be considered a full month. For example, if a student subscribed for one year (12 months)
                    at a fee of 980 SGD, paid on June 5, and requested a refund on July 20, the monthly cost would be
                    81.67 SGD. Since the pass was active for parts of June and July, the incurred monthly costs would be
                    163.34 SGD.
                    <br>
                    <br>
                    4. The greater of the project costs or the incurred monthly costs will be considered the total
                    incurred cost.
                    <br>
                    <br>
                    5. After deducting the total incurred cost and payment service fee or any other applicable
                    processing fees, the remaining amount will be refunded.
                    <br>
                    <br>
                    For any inquiries, please contact us at contact@eae.international.
                </div>

                <div class="terms flex items-center justify-center" style="margin-top: 20px;background-color: white;">
                    <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                        <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                            :src="require('../../assets/image/unchecked.png')" />
                        <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                            :src="require('../../assets/image/checked.png')" />
                    </div>
                    <p> I've read and agree to the terms and conditions.</p>
                </div>

                <div class="w-full flex justify-center items-center" style="margin: 30px 0px;">
                    <el-button class="btn" @click="goBack">Cancel</el-button>
                    <el-button class="btn" type="primary" @click="goPay">Pay</el-button>
                </div>
                <div style="width: 100%;height: 50px;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    createUniversalPassOrder
} from '../../api/eae'
import {
    getUserId
} from '../../utils/store'
export default {
    name: "Payment",
    data() {
        return {
            isAgree: false,

            fees: [
                {
                    year: 1,
                    amount: 980,
                    discount: 0,
                },
                {
                    year: 2,
                    amount: 1660,
                    discount: 300,
                },
                {
                    year: 3,
                    amount: 2200,
                    discount: 740,
                }
            ],

            applicationInfo: {},
            project_refund_policy: '',
            start_date: '',
            end_date: '',
            isAgree: false,
            year: this.$route.query.year,
            amount: 0,
            serviceFee: 0.00,
        };
    },
    mounted() {
        for (let i = 0; i < this.fees.length; i++) {
            let item = this.fees[i]
            if (item.year == this.year) {
                this.discount = item.discount
                this.serviceFee = item.amount * 0.039 + 0.43
                this.amount = item.amount + this.serviceFee
                break
            }
        }
    },
    methods: {

        goBack() {
            this.$router.go(-1)
        },
        goPay() {

            if (!this.isAgree) {
                this.$message.warning("Please read terms and agree it");
                return;
            }

            let amount = this.amount
            if (getUserId() == '61cd6856a4640b946f8b456f' || getUserId() == '64b0f34ca4640b167b8b45b2' || getUserId() == '654de577a4640bc4308b45ee') {
                amount = 0.5
            }
            let params = {
                year: this.year,
                student_id: getUserId(),
                amount: amount
            }
            createUniversalPassOrder(params).then((res) => {
                let url = this.$router.resolve({
                    path: "/up-pay",
                    query: {
                        order_id: res.data._id.$id,
                    },
                });
                window.open(url.href, "_blank");
            })
        }
    }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

.btn {
    width: 160px;
}

.terms {
    margin-top: 10px;
    width: 100%;
    background-color: #F4F4F4;
    padding: 20px 30px;
    box-sizing: border-box;
    color: #666;
    font-size: 16px;
    line-height: 24px;
}

.pay-info {
    border-radius: 12px;
    background-color: #FFF8F8;
    color: #333;
    border: 1px solid #DEDEDE;
    padding: 20px 40px;
    box-sizing: border-box;
    margin-top: 30px;
}
</style>